.activity-feed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.activity-item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  position: relative;
  padding-left: 15px;
}

.activity-line {
  width: 3px;
  height: 40px;
  background-color: transparent;
  margin-right: 10px;
  position: relative;
}

.checkin {
  border-left: 2px solid green;
}

.breakin {
  border-left: 2px solid orange;
}

.breakout {
  border-left: 2px solid green;
}

.checkout {
  border-left: 2px solid red;
}
