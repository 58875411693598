/* .fc-daygrid-day {
  border: 2px solid #333;
} */

/* .full-calendar-container {
  border: 2px solid #333;
} */

/* .fc-day {
  border: 1px solid #ccc;
} */

.fc .fc-col-header-cell-cushion {
  border-bottom: 2px solid #ccc;
}

tbody td {
  border: 2px solid #ccc;
}

thead tr {
  border-bottom: 2px solid #ccc;
}

.event-popover {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Additional styles to customize the appearance of the popover */
}

/* .fc-daygrid-day-left {
  border: 1px solid #ccc; 
} */

/* .full-calendar-container .fc-daygrid-day-left {
  border: 1px solid #ccc; 
} */

/*
.root .fc-dayGridMonth-button .fc-timeGridWeek-button .fc-timeGridDay-button .fc-listMonth-button {
  font-size: 10px;
  padding: 2px 2px;
} */

/* :root {
  .fc-dayGridMonth-button {
    font-size: 10px;
    padding: 2px 2px;
  }
} */

/* .fc-button-group {
  --fc-button-bg-color: #009ef7;
  --fc-button-border-color: #009ef7;
} */
