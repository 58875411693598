/* .custom-tooltip {
    position: relative;
  }
  
  .custom-tooltip .tooltip-content {
    display: none;
    position: absolute;
    top: -270%; 
    left: -600%;
    width: 190px; 
    height: 100px;
    background-color: #e6e4e4;
    color: #393838;
    padding: 10px;
    z-index: 1;
    text-align: left;
  }
  
  .custom-tooltip .tooltip-content:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 80%;
    transform: translateX(-50%);
    width: 0;
    height: 0;

    border-style: solid;
    border-width: 8px 8px 0 8px;
   
    border-color: #e6e4e4 transparent transparent transparent;
  }
  
  .custom-tooltip .btn {
    cursor: pointer;
  }
  
  .custom-tooltip:hover .tooltip-content {
    display: block;
  } */
  
/*  
   .custom-tooltip {
    position: relative;
  }
  
  .custom-tooltip .tooltip-content {
    display: none;
    position: absolute;
    top: -390%;
    left: -780%;
    width: 190px;
    height: 120px;
    background-color: #f5f5f5;
    color: #393838;
    padding: 10px;
    z-index: 1;
    text-align: left;
    overflow: auto;
    box-shadow: 3px 5px 5px rgba(8, 8, 8, 0.2);
    border-radius: 5px  ;
    padding: 16px;
}
  
  .custom-tooltip .tooltip-content::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-top-color: #e6e4e4; 
  }
  
  .custom-tooltip .btn {
    cursor: pointer;
  }
  
  .custom-tooltip:hover .tooltip-content {
    display: block;
  }
   */
   .custom-tooltip {
    position: relative;
  }
  
  .custom-tooltip .tooltip-content {
    display: none;
    position: absolute;
    top: -390%;
    left: -780%;
    width: 190px;
    height: 120px;
    background-color: #f5f5f5;
    color: #393838;
    padding: 10px;
    z-index: 1;
    text-align: left;
    overflow: auto;
    box-shadow: 3px 5px 5px rgba(8, 8, 8, 0.2);
    border-radius: 5px;
    padding: 16px;
  }
  
  .custom-tooltip .tooltip-content::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-top-color: #e6e4e4;
  }
  
  .custom-tooltip .btn {
    cursor: pointer;
  }
  
  .custom-tooltip:hover .tooltip-content {
    display: block;
  }
  
  
  .custom-tooltip .tooltip-content::after {
    content: '';
    position: absolute;
    top: -10px; 
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-bottom-color: #f5f5f5;
  }
  