.weekend {
  border-bottom: 3px solid orange;
  padding-bottom: 3px;
}

.working {
  border-bottom: 3px solid green;
  padding-bottom: 3px;
}

.notWorking {
  border-bottom: 3px solid red;
  padding-bottom: 3px;
}

.popover {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notWorking.hovered .popover {
  display: block;
}

.calendarEvent {
  cursor: pointer;
  border-bottom: 3px solid red;
  padding-bottom: 3px;
}
